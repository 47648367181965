import React from 'react'

import Layout from '../components/layout'
import Emoji from '../components/emoji'
import SEO from '../components/seo'
import Intro from '../components/intro'

const IndexPage = () => (
  <Layout>
    <SEO 
      title="Home" 
      keywords={[`ui`, `ui engineer`, `react developer`]}
      metaDescription={'User Interface Engineer'} />
    <h1 class="type-first">Hello! <Emoji symbol="👋" /> </h1>
    <Intro />
  </Layout>
)

export default IndexPage
