import React from 'react'
import { Link } from 'gatsby'

const Intro = () => (
    <div style={{ display:`flex`, flexDirection: `column`, marginBottom: `1em` }}> 
      <p class="type-header">My name is Anika Corpus and I am a User Interface Engineer.</p>   
      <a href="https://www.linkedin.com/in/anika-corpus?trk=profile-badge">
        <img style={{ marginTop: `1.5em` }}
        src="https://static.licdn.com/scds/common/u/images/logos/linkedin/logo_linkedin_93x21_v2.png" alt="LinkedIn"/>
      </a>
     </div>
)

export default Intro